/* Desktop */
.SortMenu.desktop {
    display: none;
}

/* Mobile */

/* .SortMenu.mobile,
.SortMenu.mobile .Button {
    align-items: center;
    display: inline-flex;
    max-height: 34px;
    height: 34px;
    max-width: 100px;
    justify-content: center;
    text-align: center;
    width: 100px;
}

.SortMenu.mobile .Button {
    background-color: var(--background-light-color);
    border: none;
    color: var(--text-dark-color);
    font-weight: 400;
} */

.SortMenu .sort-order-text.active {
    font-weight: bold;
}

.SortMenu .sort-order-icon-container {
    display: block;
}

.SortMenu.mobile .drawer {
    background-color: var(--background-light-color);
    border-radius: var(--spacing-4x);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: var(--spacing-2x);
    padding-top: var(--spacing-2x);
    width: 100%;
}

.SortMenu.mobile .drawer .sort-order {
    text-align: left;
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
    width: 100%;
}

.SortMenu.mobile .drawer .sort-order .Row {
    align-items: center;
    border-bottom: 1px solid var(--border-light-color);
    justify-content: flex-start;
    height: 50px;
    text-align: left;
    width: 100%;
}

.SortMenu.mobile .drawer .sort-order:last-child .Row {
    border-bottom: 0px;
}

.SortMenu.mobile .drawer .spacer {
    width: 44px;
}

.SortMenu.mobile .drawer .header {
	align-items: center;
	display: flex;
	height: 52px;
	justify-content: space-between;
	padding: var(--spacing-1x) var(--spacing-1x);
	width: 100%;
}

@media only screen and (min-width: 600px) {

    /* Mobile */
    .SortMenu.mobile {
        display: none;
    }

    .SortMenu .sort-order-icon-container {
        display: none;
    }

    /* Desktop */
    .SortMenu.desktop {
        display: flex;
    }

    .SortMenu.desktop,
    .SortMenu.desktop .szh-menu-button {
        align-items: center;
        display: flex;
        max-height: 34px;
        max-width: 70px;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .SortMenu.desktop .szh-menu-button {
        background-color: var(--background-light-color);
        border: 2px solid #ccc8c0;
        color: var(--text-dark-color);
    }

    .SortMenu.desktop .szh-menu-button:hover,
    .SortMenu.desktop .szh-menu-button.szh-menu-button--open {
        background-color: var(--background-light-color-hover);
    }

    .SortMenu.desktop .szh-menu {
        background-color: var(--background-light-color);
        padding-left: var(--spacing-2x);
        padding-right: var(--spacing-2x);
    }

    .SortMenu.desktop .szh-menu__item {
        border-radius: var(--spacing-1x);
        padding-left: var(--spacing-2x);
        padding-right: var(--spacing-2x);
    }

    .SortMenu.desktop .szh-menu__item.szh-menu__item--hover {
        background-color: var(--background-light-color-hover);
    }
    
}