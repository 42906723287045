/* One */

.mB-1 {
    margin-bottom: var(--spacing-1x);
}

.mT-1 {
    margin-top: var(--spacing-1x);
}

.mL-1 {
    margin-left: var(--spacing-1x);
}

.mR-1 {
    margin-right: var(--spacing-1x);
}

.pB-1 {
    padding-bottom: var(--spacing-1x);
}

.pT-1 {
    padding-top: var(--spacing-1x);
}

.pL-1 {
    padding-left: var(--spacing-1x);
}

.pR-1 {
    padding-right: var(--spacing-1x);
}

/* Two */

.mB-2 {
    margin-bottom: var(--spacing-2x);
}

.mT-2 {
    margin-top: var(--spacing-2x);
}

.mL-2 {
    margin-left: var(--spacing-2x);
}

.mR-2 {
    margin-right: var(--spacing-2x);
}

.pB-2 {
    padding-bottom: var(--spacing-2x);
}

.pT-2 {
    padding-top: var(--spacing-2x);
}

.pL-2 {
    padding-left: var(--spacing-2x);
}

.pR-2 {
    padding-right: var(--spacing-2x);
}

/* Three */

.mB-3 {
    margin-bottom: var(--spacing-3x);
}

.mT-3 {
    margin-top: var(--spacing-3x);
}

.mL-3 {
    margin-left: var(--spacing-3x);
}

.mR-3 {
    margin-right: var(--spacing-3x);
}

.pB-3 {
    padding-bottom: var(--spacing-3x);
}

.pT-3 {
    padding-top: var(--spacing-3x);
}

.pL-3 {
    padding-left: var(--spacing-3x);
}

.pR-3 {
    padding-right: var(--spacing-3x);
}

/* Four */

.mB-4 {
    margin-bottom: var(--spacing-4x);
}

.mT-4 {
    margin-top: var(--spacing-4x);
}

.mL-4 {
    margin-left: var(--spacing-4x);
}

.mR-4 {
    margin-right: var(--spacing-4x);
}

.pB-4 {
    padding-bottom: var(--spacing-4x);
}

.pT-4 {
    padding-top: var(--spacing-4x);
}

.pL-4 {
    padding-left: var(--spacing-4x);
}

.pR-4 {
    padding-right: var(--spacing-4x);
}


/* Five */

.mB-5 {
    margin-bottom: var(--spacing-5x);
}

.mT-5 {
    margin-top: var(--spacing-5x);
}

.mL-5 {
    margin-left: var(--spacing-5x);
}

.mR-5 {
    margin-right: var(--spacing-5x);
}

.pB-5 {
    padding-bottom: var(--spacing-5x);
}

.pT-5 {
    padding-top: var(--spacing-5x);
}

.pL-5 {
    padding-left: var(--spacing-5x);
}

.pR-5 {
    padding-right: var(--spacing-5x);
}

/* Six */

.mB-6 {
    margin-bottom: var(--spacing-6x);
}

.mT-6 {
    margin-top: var(--spacing-6x);
}

.mL-6 {
    margin-left: var(--spacing-6x);
}

.mR-6 {
    margin-right: var(--spacing-6x);
}

.pB-6 {
    padding-bottom: var(--spacing-6x);
}

.pT-6 {
    padding-top: var(--spacing-6x);
}

.pL-6 {
    padding-left: var(--spacing-6x);
}

.pR-6 {
    padding-right: var(--spacing-6x);
}

/* Seven */

.mB-7 {
    margin-bottom: var(--spacing-7x);
}

.mT-7 {
    margin-top: var(--spacing-7x);
}

.mL-7 {
    margin-left: var(--spacing-7x);
}

.mR-7 {
    margin-right: var(--spacing-7x);
}

.pB-7 {
    padding-bottom: var(--spacing-7x);
}

.pT-7 {
    padding-top: var(--spacing-7x);
}

.pL-7 {
    padding-left: var(--spacing-7x);
}

.pR-7 {
    padding-right: var(--spacing-7x);
}

/* Eight */

.mB-8 {
    margin-bottom: var(--spacing-8x);
}

.mT-8 {
    margin-top: var(--spacing-8x);
}

.mL-8 {
    margin-left: var(--spacing-8x);
}

.mR-8 {
    margin-right: var(--spacing-8x);
}

.pB-8 {
    padding-bottom: var(--spacing-8x);
}

.pT-8 {
    padding-top: var(--spacing-8x);
}

.pL-8 {
    padding-left: var(--spacing-8x);
}

.pR-8 {
    padding-right: var(--spacing-8x);
}

/* Ten */

.mB-10 {
    margin-bottom: var(--spacing-10x);
}

.mT-10 {
    margin-top: var(--spacing-10x);
}

.mL-10 {
    margin-left: var(--spacing-10x);
}

.mR-10 {
    margin-right: var(--spacing-10x);
}

.pB-10 {
    padding-bottom: var(--spacing-10x);
}

.pT-10 {
    padding-top: var(--spacing-10x);
}

.pL-10 {
    padding-left: var(--spacing-10x);
}

.pR-10 {
    padding-right: var(--spacing-10x);
}

/* Twelve */

.mB-12 {
    margin-bottom: var(--spacing-12x);
}

.mT-12 {
    margin-top: var(--spacing-12x);
}

.mL-12 {
    margin-left: var(--spacing-12x);
}

.mR-12 {
    margin-right: var(--spacing-12x);
}

.pB-12 {
    padding-bottom: var(--spacing-12x);
}

.pT-12 {
    padding-top: var(--spacing-12x);
}

.pL-12 {
    padding-left: var(--spacing-12x);
}

.pR-12 {
    padding-right: var(--spacing-12x);
}

/* Fourteen */

.mB-14 {
    margin-bottom: var(--spacing-14x);
}

.mT-14 {
    margin-top: var(--spacing-14x);
}

.mL-14 {
    margin-left: var(--spacing-14x);
}

.mR-14 {
    margin-right: var(--spacing-14x);
}

.pB-14 {
    padding-bottom: var(--spacing-14x);
}

.pT-14 {
    padding-top: var(--spacing-14x);
}

.pL-14 {
    padding-left: var(--spacing-14x);
}

.pR-14 {
    padding-right: var(--spacing-14x);
}


/* Fifteen */

.mB-15 {
    margin-bottom: var(--spacing-15x);
}

.mT-15 {
    margin-top: var(--spacing-15x);
}

.mL-15 {
    margin-left: var(--spacing-15x);
}

.mR-15 {
    margin-right: var(--spacing-15x);
}

.pB-15 {
    padding-bottom: var(--spacing-15x);
}

.pT-15 {
    padding-top: var(--spacing-15x);
}

.pL-15 {
    padding-left: var(--spacing-15x);
}

.pR-15 {
    padding-right: var(--spacing-15x);
}


/* Twenty */

.mB-20 {
    margin-bottom: var(--spacing-20x);
}

.mT-20 {
    margin-top: var(--spacing-20x);
}

.mL-20 {
    margin-left: var(--spacing-20x);
}

.mR-20 {
    margin-right: var(--spacing-20x);
}

.pB-20 {
    padding-bottom: var(--spacing-20x);
}

.pT-20 {
    padding-top: var(--spacing-20x);
}

.pL-20 {
    padding-left: var(--spacing-20x);
}

.pR-20 {
    padding-right: var(--spacing-20x);
}


/* Thirty */

.mB-30 {
    margin-bottom: var(--spacing-30x);
}

.mT-30 {
    margin-top: var(--spacing-30x);
}

.mL-30 {
    margin-left: var(--spacing-30x);
}

.mR-30 {
    margin-right: var(--spacing-30x);
}

.pB-30 {
    padding-bottom: var(--spacing-30x);
}

.pT-30 {
    padding-top: var(--spacing-30x);
}

.pL-30 {
    padding-left: var(--spacing-30x);
}

.pR-30 {
    padding-right: var(--spacing-30x);
}



/* Forty */

.mB-40 {
    margin-bottom: var(--spacing-40x);
}

.mT-40 {
    margin-top: var(--spacing-40x);
}

.mL-40 {
    margin-left: var(--spacing-40x);
}

.mR-40 {
    margin-right: var(--spacing-40x);
}

.pB-40 {
    padding-bottom: var(--spacing-40x);
}

.pT-40 {
    padding-top: var(--spacing-40x);
}

.pL-40 {
    padding-left: var(--spacing-40x);
}

.pR-40 {
    padding-right: var(--spacing-40x);
}



