.AnonymousFooter {
    align-items: flex-start;
    color: var(--text-light-color);
    background-color: var(--background-dark-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--spacing-20x) var(--spacing-4x);
    min-height: 500px;
    width: 100%;
}

.DiscoverSection .AnonymousFooter {
    min-height: 500px;
    position: absolute;
    bottom: 0;
}

.AnonymousFooter .app-name {
    color: var(--text-light-color);
    font-size: var(--font-size-medium);
    font-family: var(--special-font-family);
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

.AnonymousFooter .copyright {
    color: var(--text-medium-color);
    font-size: var(--font-size-xsmall);
    max-width: 75%;
    text-align: left;
}

.AnonymousFooter .Column {
    justify-content: flex-start;
}

.AnonymousFooter a {
    color: var(--text-light-color);
}

.AnonymousFooter .MaxWidthContainer {
    align-items: space-between;
    display: flex;
    justify-content: flex-start;
}

.AnonymousFooter .info {
    width: 25%;
}

.AnonymousFooter .links {
    align-items: flex-start;
    margin-top: var(--spacing-3x);
    justify-content: flex-start;
    width: 75%;
}

.AnonymousFooter .expert-link {
    margin-top: var(--spacing-2x);
    font-size: var(--font-size-small);
    color: var(--light-grey1-color);
    width: 100%;
}

.AnonymousFooter .expert-link:hover {
    opacity: 50%;
}

.AnonymousFooter .links .Column:first-child {
    width: 75%;
}

.AnonymousFooter .links .Column:last-child {
    width: 25%;
}

.AnonymousFooter .links .experts .one,
.AnonymousFooter .links .experts .two,
.AnonymousFooter .links .experts .three {
    min-width: 33%;
    flex-grow: 1;
}

.AnonymousFooter .links .pages .one,
.AnonymousFooter .links .other-sites .one {
    width: 100%;
}

.AnonymousFooter .links .experts .two {
    padding-left: var(--spacing-8x);
}

.AnonymousFooter .links .experts .three {
    padding-left: var(--spacing-16x);
}

.AnonymousFooter .header {
    color: var(--text-light-color);
    font-weight: bold;
}

.AnonymousFooter .experts {
    width: 100%;
}

.AnonymousFooter .pages,
.AnonymousFooter .other-sites {
    padding-left: var(--spacing-18x);
    width: 100%;
}

@media only screen and (max-width: 1040px) {
    .AnonymousFooter .MaxWidthContainer {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .AnonymousFooter .info {
        width: 100%;
    }

    .AnonymousFooter .links {
        margin-top: var(--spacing-12x);
        width: 100%;
    }
}

@media only screen and (max-width: 840px) {

    .AnonymousFooter .copyright {
        max-width: 100%;
    }

    .AnonymousFooter .links {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .AnonymousFooter .links .Column:first-child {
        width: 100%;
    }

    .AnonymousFooter .links .Column:last-child {
        width: 100%;
    }

    .AnonymousFooter .pages {
        margin-top: var(--spacing-12x);
    }

    .AnonymousFooter .pages,
    .AnonymousFooter .other-sites {
        padding-left: 0;
    }
}

@media only screen and (max-width: 600px) {

    .AnonymousFooter .experts {
        flex-direction: column;
    }

    .AnonymousFooter .links .experts .one,
    .AnonymousFooter .links .experts .two,
    .AnonymousFooter .links .experts .three {
        margin-top: var(--spacing-6x);
        min-width: 100%;
    }

    .AnonymousFooter .links .experts .two {
        padding-left: 0;
    }
    
    .AnonymousFooter .links .experts .three {
        padding-left: 0;
    }
}
