/** @format */

.HeroSection .content {
    height: 100%;
    min-height: calc(100vh - var(--standard-header-height));
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;
    width: 100%;
    z-index: 2;
}

.HeroSection .video {
    background-color: black;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    width: 100vw;
    z-index: 1;
}

.HeroSection .heading {
    position: relative;
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 1.25;
    max-width: min(90%, 840px);
    margin-left: auto;
    margin-right: auto;
    padding-top: 126px;
    padding-left: var(--spacing-3x);
    padding-right: var(--spacing-3x);
    text-align: center;
    width: 100%;
}

.HeroSection .subheading {
    font-weight: 300;
    position: relative;
    line-height: 1.5;
    max-width: min(90%, 840px);
    margin-left: auto;
    margin-right: auto;
    padding-top: var(--spacing-4x);
    padding-left: var(--spacing-3x);
    padding-right: var(--spacing-3x);
    text-align: center;
    width: 100%;
}

.HeroSection .books-column-container {
    position: absolute;
    bottom: 0;
    max-height: 65vh;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
}

.HeroSection .books-column-container .BooksColumn {
    padding-top: 680px;
    margin-left: var(--spacing-2x);
    margin-right: var(--spacing-2x);
}

.HeroSection .people-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: min(90%, 840px);
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--spacing-10x);
    z-index: 100;
}

.HeroSection .people-container .avatar-bubble-link {
    min-width: 90px;
    min-height: 90px;
    margin-left: var(--spacing-2x);
    margin-left: var(--spacing-2x);
}

.HeroSection .people-container .avatar-bubble-link:nth-child(2n) {
    margin-top: var(--spacing-10x);
}

.HeroSection .bottom-banner {
    display: none;
    background-color: rgb(255, 253, 146);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    bottom: 0;
    color: var(--black-color);
    cursor: pointer;
    height: 60px;
    font-weight: bold;
    justify-content: center;
    left: 0;
    position: absolute;
    width: 100%;
}

.HeroSection .bottom-fade {
    display: none;
    background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        var(--background-light-color) 70%
    );
    bottom: 0;
    height: 200px;
    left: 0;
    position: absolute;
    width: 100%;
}

.HeroSection .btn-how-it-works {
    animation-name: bouncing-arrow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: center bottom;
    align-items: center;
    border-radius: 50%;
    bottom: 210px;
    display: flex;
    height: 48px;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 48px;
}

.HeroSection .mobile-subheading {
    display: none;
}

.HeroSection .search-box {
    margin-top: var(--spacing-10x);
    position: relative;
}

.HeroSection .search-box .left {
    background: rgba(0, 0, 0, 0)
        url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E")
        repeat scroll 0% 0%;
    content: "";
    height: 1rem;
    left: 1rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem;
}

.HeroSection .search-box .search-input {
    border-radius: var(--spacing-8x);
    border: 2px solid black;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    caret-color: #5a5e9a;
    color: #23263b;
    font: inherit;
    max-width: 100%;
    min-width: 300px;
    min-height: 44px;
    padding-left: 2.5rem;
    z-index: 3;
}

@media only screen and (max-width: 600px) {
    .HeroSection .heading {
        padding-top: var(--spacing-6x);
        font-size: 34px;
        max-width: 100%;
    }

    .HeroSection .subheading {
        display: none;
    }

    .HeroSection .mobile-subheadings-container {
        margin-top: var(--spacing-4x);
        z-index: 3;
    }

    .HeroSection .mobile-subheading {
        display: block;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    .HeroSection .mobile-subheading a {
        text-decoration: underline;
    }

    .HeroSection .people-container {
        margin-top: var(--spacing-6x);
        max-width: 80%;
    }

    .HeroSection .search-box {
        margin-top: var(--spacing-6x);
    }

    .HeroSection .people-container .avatar-bubble-link {
        min-width: 40px;
        min-height: 40px;
        margin-left: 0;
        margin-right: 0;
    }

    .HeroSection .people-container .avatar-bubble-link .AvatarBubble {
        width: 40px !important;
        height: 40px !important;
    }

    .HeroSection .books-column-container {
        max-height: 65vh;
    }

    .HeroSection .books-column-container .BooksColumn {
        padding-top: 450px;
    }

    .HeroSection .btn-how-it-works {
        bottom: 140px;
    }
}

@keyframes bouncing-arrow {
    0% {
        transform: translate(-50%, 0);
    }
    50% {
        transform: translate(-50%, -40px);
    }
    100% {
        transform: translate(-50%, 0);
    }
}
