/** @format */

.MaxWidthContainer {
	max-width: var(--mobile-max-page-width);
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

@media only screen and (min-width: 980px) {
	.MaxWidthContainer {
		max-width: var(--max-page-width);
		margin-left: auto;
		margin-right: auto;
	}
}
