/** @format */

.Crumb {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Crumb.displayName {
	max-width: 106px;
    flex-shrink: 0;
}
.Crumb.bookTitle {
    flex-shrink: 1;
}

.Crumb .link {
	color: var(--text-dark-color);
	font-size: var(--font-size-small);
	text-decoration: underline;
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    display: block;
}

.Crumb.divider {
    flex-shrink: 0;
}

.Crumb .divider {
    margin-left: var(--spacing-2x);
    margin-right: var(--spacing-2x);
}

@media only screen and (min-width: 600px) {
	.Crumb {
		max-width: 100%;
	}
}
