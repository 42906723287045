.Navigation {
    background-color: var(--background-light-color);
    color: var(--text-dark-color);
    height: var(--standard-header-height);
    left: 0;
    padding: var(--spacing-4x) 0;
    position: sticky;
    top: 0;
    width: 100%;
}

.Navigation .title {
    margin-left: calc(-1 * var(--spacing-1x));
}

.Navigation .btn-back.hidden {
    opacity: 0;
    margin-left: 0px;
    width: 4px;
    pointer-events: none;
}

.Navigation .btn-back {
    padding-left: 0;
    padding-right: var(--spacing-1x);
    margin-left: -8px;
    transition: all 100ms ease;
    height: auto;
    width: 44px;
}