.SignUpFooter {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.SignUpFooter .app-name {
    color: var(--text-dark-color);
    font-size: var(--font-size-medium);
    font-family: var(--special-font-family);
    font-style: normal;
    font-weight: 400;
    text-align: center;
    padding: var(--spacing-8x) 0 var(--spacing-14x) 0;
}

@media only screen and (min-width: 600px) {

    .SignUpFooter .app-name {
        font-size: var(--font-size-medium);
        margin-top: 30vh;
    }
}