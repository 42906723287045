.EmailLogInForm {
    width: 100%;
}

.EmailLogInForm .EmailInput {
    margin-top: var(--spacing-6x);
}

.EmailLogInForm button {
    margin-top: var(--spacing-6x);
}

.EmailLogInForm .already-link {
    margin-top: var(--spacing-8x);
}

@media only screen and (min-width: 600px) {
    .EmailLogInForm .already-link {
        margin-top: var(--spacing-4x);
    }
    
}
