.NavPage {
    align-items: flex-start;
    background-color: var(--background-dark-color);
    display: flex;
    justify-content: center;
    min-height: calc(100vh - var(--standard-header-height));
    height: 100%;
    transition: all 150ms linear;
    width: 100%;
}

.NavPage .MaxWidthContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.NavPage .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 0;
    margin-left: -20px;
    width: 100%;
}

.NavPage .Label {
    color: var(--text-light-color);
    font-size: var(--font-size-regular);
}
