/** @format */

.BookGridItem {
	cursor: pointer;
	transition: all 100ms linear;
}

.BookGridItem .cover {
	aspect-ratio: 20/31;
	transition: all 100ms linear;
    margin-bottom: 0.5rem;
}

.BookGridItem .Label {
	max-width: 100%;
    display: inline;
    margin-inline: calc(0.3em* -1);
    padding-inline: 0.3em;
}

.BookGridItem .cover img {
	box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 2px -1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 100%;
    min-width: 100%;
}

.BookGridItem a {
	max-width: 100%;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .BookGridItem:hover .cover {
        transform: translate(0, -12px) rotate(1deg) scaleX(1.02) scaleY(1.02);
    }

    .BookGridItem:hover .Label {
        background-image: linear-gradient(
                to bottom right,
                transparent 50%,
                rgba(255, 226, 130, 0.5) 50%
            ),
            linear-gradient(rgba(255, 226, 130, 0.5), rgba(255, 226, 130, 0.5)),
            linear-gradient(
                to top left,
                transparent 50%,
                rgba(255, 226, 130, 0.5) 50%
            );
        background-color: transparent;
        background-size: 0.25em 1.4em,calc(100% - 0.25em * 2 + 1px) 1.4em,0.25em 1.4em;
        background-repeat: no-repeat;
        background-position: 0, 50%, 100%;
    }
}
