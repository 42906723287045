.PeopleHeader {
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    max-width: 100%;
}

.PeopleHeader .Label.title {
    font-size: var(--font-size-large);
    font-weight: 800;
    line-height: 50px;
}

.PeopleHeader .SortMenu.mobile {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: var(--spacing-2x);
    width: auto;
}

.PeopleHeader .SortMenu.mobile .Row {
    justify-content: flex-end;
}

@media only screen and (min-width: 600px) {
    .PeopleHeader {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
    }

    .PeopleHeader .Label.title {
        font-size: var(--font-size-large);
    }

    .PeopleHeader .SortMenu.desktop {
        margin-top: var(--spacing-3x);
    }
}
