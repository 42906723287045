.AnonymousHeader {
    background-color: var(--background-light-color);
    /* border-bottom: 1px solid var(--border-light-color); */
    color: var(--text-light-color);
    height: var(--standard-header-height);
    left: 0;
    position: sticky;
    top: 0;
    z-index: 10;
}

.AnonymousHeader .auth-buttons {
    align-items: center;
    display: flex;
}

.AnonymousHeader.light {
    background-color: var(--background-light-color);
    color: var(--text-dark-color);
}

.AnonymousHeader.no-max-width .MaxWidthContainer {
    max-width: unset;
    padding: 0;
}

.AnonymousHeader .app-name {
    color: var(--text-dark-color);
    font-size: 34px;
    font-family: var(--special-font-family);
    font-style: normal;
    font-weight: bold;
}

.AnonymousHeader.light .app-name {
    color: var(--text-dark-color);
}

.AnonymousHeader {
    height: var(--standard-header-height);
}

.AnonymousHeader .login,
.AnonymousHeader .people,
.AnonymousHeader .books,
.AnonymousHeader .signup {
    color: var(--text-dark-color);
    font-weight: bold;
    margin-right: var(--spacing-2x);
}

.AnonymousHeader .signup {
    background-color: black;
    border-radius: 6px;
    color: var(--text-light-color);
    font-size: var(--font-size-small);
    padding: var(--spacing-2x) var(--spacing-3x);
    text-transform: none;
    margin-left: var(--spacing-2x);
    order: 1;
    margin-right: var(--spacing-2x);;
    transition: all 150ms ease;
}

.AnonymousHeader .signup:after {
    content: "Join"
}

.AnonymousHeader .books,
.AnonymousHeader .people {
    display: none;
}

.AnonymousHeader .books,
.AnonymousHeader .people,
.AnonymousHeader .login {
    border-radius: 30px;
    color: var(--text-dark-color);
    font-size: 16px;
    padding: var(--spacing-3x) var(--spacing-2x);
    text-transform: none;
}

.AnonymousHeader .login {
    order: 2;
    margin-right: 0;
    padding-right: 0;
}

.AnonymousHeader .contents {
    padding: 0 var(--spacing-4x);
}

/* .AnonymousHeader.isAuth .people,
.AnonymousHeader.isAuth .login,
.AnonymousHeader.isAuth .signup {
    display: none;
} */

@media only screen and (max-width: 1040px) {
    .AnonymousHeader {
        padding: 0 var(--spacing-4x);
    }
}

@media only screen and (min-width: 1000px) {
    .AnonymousHeader .signup:hover {
        background-color: transparent;
        color: black;
    }
}

@media only screen and (max-width: 600px) {

    .AnonymousHeader {
        padding: 0 0;
    }
}

@media only screen and (min-width: 600px) {

    .AnonymousHeader .contents {
        padding: 0 0;
    }
    
    .AnonymousHeader.no-max-width .MaxWidthContainer {
        padding: var(--spacing-6x);
    }

    .AnonymousHeader .books,
    .AnonymousHeader .people {
        display: flex;
    }

    .AnonymousHeader .app-name {
        font-size: var(--font-size-large);
    }

    .AnonymousHeader .login {
        order: 1;
    }
    .AnonymousHeader .signup {
        order: 2;
        content: "Join";
        margin-right: 0;
        margin-left: var(--spacing-6x);
    }
    .AnonymousHeader .signup:after {
        content: "Sign up";
    }
}
