.BookHeader {
    height: 50px;
    left: 0;
    position: sticky;
    top: var(--standard-header-height);
    z-index: 1;
    width: 100%;
    background: rgba(255, 250, 240, 0.95);
}

.BookHeader .MaxWidthContainer {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;

}

.BookHeader .Crumbs {
    flex-grow: 1;
    width: 100%;
}

.BookHeader .Actions {
    flex-grow: 1;
}

@media only screen and (max-width: 1040px) {
    .BookHeader .MaxWidthContainer {
        max-width: calc(100vw - var(--spacing-8x));
    }
}