.PageTitles .MaxWidthContainer {
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
    padding-top: var(--spacing-4x);
}

.PageTitles .title {
    font-size: var(--font-size-medium);
    margin-top: var(--spacing-4x);
    text-align: left;
}

.PageTitles .subtitle {
    font-size: var(--font-size-small);
    font-weight: 400;
    margin-bottom: var(--spacing-2x);
    text-align: left;
}

@media only screen and (min-width: 600px) {
    .PageTitles .title {
        text-align: center;
    }
    
    .PageTitles .subtitle {
        text-align: center;
    }
    
}