.UploadAvatarForm {
    align-items: center;
    display: flex;
    justify-content: center;
	padding: var(--spacing-2x) var(--spacing-2x) 0;
    flex-grow: 1;
    width: 100%;
}

.UploadAvatarForm input[type="file"] {
	display: none;
}

.UploadAvatarForm .fileUpload,
.UploadAvatarForm .fileRemove {
	align-items: center;
    border: 0px;
    border-radius: var(--spacing-1x);
    background-color: var(--white-color);
    color: var(--text-dark-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: var(--font-size-small);
    line-height: 40px;
    font-weight: bold;
    transition: all 100ms ease;
    max-width: 120px;
    text-align: center;
    width: 100%;
}

.UploadAvatarForm:hover .fileUpload {
    background-color: var(--light-grey1-color);
    color: var(--text-dark-color);
}

.UploadAvatarForm .fileRemove {
	background-color: var(--grey2-color);
	color: var(--text-dark-color);
}

.UploadAvatarForm .fileUpload svg,
.UploadAvatarForm .fileRemove svg {
	margin-left: var(--spacing-2x);
	margin-top: 1px;
}

@media only screen and (min-width: 600px) {
	.UploadAvatarForm {
        justify-content: flex-end;
        padding-right: 0;
    }
}
