/** @format */

.ProfilePhotoSection {
	align-items: flex-start;
	color: var(--light-text-color);
	flex-direction: column;
	flex-grow: 1;
}

.ProfilePhotoSection .headers,
.ProfilePhotoSection .form-container {
	width: 100%;
}

.ProfilePhotoSection .form-container {
	align-items: center;
	justify-content: center;
	margin-top: var(--spacing-4x);
	max-width: 100%;
}

.ProfilePhotoSection .form-container .Avatar {
	height: 120px;
    margin-top: var(--spacing-4x);
	width: 120px;
}

.ProfilePhotoSection .section-title {
	color: var(--light-text-color);
	font-size: var(--font-size-body);
}

.ProfilePhotoSection .section-subtitle {
	color: var(--grey2-color);
	font-size: var(--font-size-body);
}

@media only screen and (min-width: 600px) {
	.ProfilePhotoSection {
		flex-direction: row;
	}

	.ProfilePhotoSection .form-container {
		align-items: flex-end;
		justify-content: flex-start;
		margin-top: 0;
		max-width: min(340px, 100%);
	}
}
