.MainHeader {
    background-color: var(--background-dark-color);
    color: var(--text-light-color);
    height: var(--standard-header-height);
    left: 0;
    position: sticky;
    top: 0;
    z-index: 10;
}

.MainHeader.bottom-border {
    border-bottom: 1px solid var(--border-color);
}

.MainHeader.dark-theme {
    background-color: var(--background-dark-color);
    color: var(--text-light-color);
}

.MainHeader.light-theme {
    background-color: var(--background-light-color);
    color: var(--text-dark-color);
}

.MainHeader .app-name {
    color: var(--text-dark-color);
    font-size: var(--font-size-large);
    font-family: var(--special-font-family);
    font-style: normal;
    font-weight: bold;
}

.MainHeader.light-theme .app-name {
    color: var(--text-dark-color);
}

.MainHeader.dark-theme .app-name {
    color: var(--text-light-color);
}

.MainHeader .login,
.MainHeader .signup {
    color: var(--text-dark-color);
    font-size: var(--font-size-xsmall);
    font-weight: bold;
    margin-left: var(--spacing-8x);
}

.MainHeader .contents {
    padding: 0 0;
}

.MainHeader .auth-buttons-mobile {
    display: none;
}

.MainHeader .auth-buttons-desktop {
    display: flex;
}

.MainHeader .nav-link {
    font-weight: bold;
    margin-right: var(--spacing-5x);
}

.MainHeader .nav-link:last-child {
    margin-right: 0;
}

.MainHeader .nav-link:hover {
    opacity: 0.5;
}

@media only screen and (max-width: 1040px) {
    .MainHeader .contents {
        padding: 0 var(--spacing-4x);
    }
}

@media only screen and (max-width: 600px) {
    .MainHeader .auth-buttons-mobile {
        display: flex;
    }

    .MainHeader .auth-buttons-desktop {
        display: none;
    }
}
