.BookLoading .cover {
    height: 274px;
}

.BookLoading .title {
    height: 40px;
    margin-top: var(--spacing-6x);
    width: 276px;
}

.BookLoading .subtitle {
    height: 26px;
    width: 134px;
}

.BookLoading .authors {
    height: 18px;
    margin-top: var(--spacing-2x);
    width: 128px;
}

.BookLoading .description-container {
    margin-top: 98px;
}

.BookLoading .description {
    margin-top: -14px;
}