.ProductLoading {
    background-color: var(--white-color);
    border: 1px solid var(--border-light-color);
    border-radius: var(--spacing-2x);
    padding: var(--spacing-4x) var(--spacing-6x);
    height: 100%;
    flex-grow: 1;
    width: 100%;
}

.ProductLoading .product-header {
    border-bottom: 1px solid var(--border-light-color);
    font-size: var(--font-size-xsmall);
    padding: 0;
    padding-bottom: var(--spacing-3x);
    width: 100%;
}

.ProductLoading .product-header .title {
    font-size: 16px;
}

.ProductLoading ul {
    padding-inline-start: 0px;
}

.ProductLoading ul .feature {
    margin-top: var(--spacing-3x);
    margin-bottom: var(--spacing-3x);
}

.ProductLoading ul .feature:first-child {
    margin-top: var(--spacing-4x);
}

.ProductLoading ul .check {
    flex-shrink: 0;
}

.ProductLoading ul .feature .Label {
    font-size: 13px;
}

.ProductLoading .product-footer {
    border-top: 1px solid var(--border-light-color);
    margin-top: auto;
    padding-top: var(--spacing-3x);
    width: 100%;
}

.ProductLoading .product-footer .Button {
    justify-content: center;
    text-transform: none;
    text-align: center;
    width: 100%;
}

.ProductLoading .product-footer .Button.checkout.disabled .Loading {
    width: 40px;
}