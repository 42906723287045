/** @format */

.Avatar {
    align-items: center;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.Avatar.placeholder {
    border: 1px solid var(--border-dark-color);
    background-color: var(--off-black-color);
}

.Avatar .text {
    color: var(--text-light-color);
    text-transform: uppercase;
}
