/** @format */

.BooksColumn .BookCover {
	margin-bottom: var(--spacing-4x);
	width: 214px;
	height: 300px;
}

.BooksColumn .BookCover img {
    opacity: 0;
	width: 214px;
	height: 300px;
}

.BooksColumn .BookCover img.animateIn {
    animation-fill-mode: forwards;
    animation-name: fadeIn, yTranslateUp;
    -webkit-animation-name: fadeIn, yTranslateUp;
    animation-duration: 3s, 1s;
    -webkit-animation-duration: 3s, 1s;
}

@media only screen and (max-width: 600px) {
    .BooksColumn .BookCover {
        width: 174px;
        height: 270px;
    }
    
    .BooksColumn .BookCover img {
        width: 174px;
        height: 270px;
    }
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes yTranslateUp {
	0% {
		transform: translateY(100px);
	}
	100% {
		transform: translateY(0px);
	}
}
