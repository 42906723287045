.UpdateUsernameForm {
    width: 100%;
}

.UpdateUsernameForm button {
    margin-top: var(--spacing-2x);
}

.UpdateUsernameForm .Icon {
    height: 20px;
    margin-top: 6px;
    width: 20px;
}

.UpdateUsernameForm .already-link {
    margin-top: var(--spacing-8x);
}

.UpdateUsernameForm .StickyPlaceholder {
    font-weight: 600;
    font-size: 15px;
    left: var(--spacing-3x);
    top: 0px;
    position: absolute;
    line-height: 50px;
}

.UpdateUsernameForm .StickyPlaceholderInput.rfc-form-control input::placeholder {
	color: rgb(180,180,180);
	position: absolute;
	padding-left: 1px;
    line-height: 48px;
    top: 13px;
}

.UpdateUsernameForm .StickyPlaceholderInput.rfc-form-control {
    position: relative;
}

.UpdateUsernameForm .StickyPlaceholderInput.rfc-form-control input {
	padding-left: 123px;
    padding-bottom: 17px;
    font-size: 15px;
}

.UpdateUsernameForm .rfc-suffix .Loading,
.UpdateUsernameForm .success-0-2-3 .rfc-suffix .Loading {
    margin-top: 4px;
    margin-left: 2px;
}

.UpdateUsernameForm .error-0-2-6 .rfc-suffix .Loading {
    margin-top: -16px;
    margin-left: 2px;
}

@media only screen and (min-width: 600px) {
    .UpdateUsernameForm .already-link {
        margin-top: var(--spacing-4x);
    }
    
}
