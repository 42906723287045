/** @format */

.AddHighlights {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.AddHighlights button {
	align-items: center;
	background-color: transparent;
	border: 0px;
    border-radius: 0;
	color: var(--text-dark-color);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size-small);
	line-height: 44px;
    min-height: 56px;
	font-weight: 400;
    padding-left: var(--spacing-5x);
	transition: all 100ms ease;
	width: 100%;
}

.AddHighlights .accordian .step:first-child {
    border-top-left-radius: var(--spacing-3x);
    border-top-right-radius: var(--spacing-3x);
    border-bottom: 1px solid var(--border-medium-color);
    overflow: hidden;
}

.AddHighlights .accordian .step:last-child {
    border-top: 1px solid var(--border-medium-color);
    border-bottom-left-radius: var(--spacing-3x);
    border-bottom-right-radius: var(--spacing-3x);
    overflow: hidden;
}

.AddHighlights button p {
    color: var(--text-dark-color);
    font-weight: 400;
    text-align: left;
}

.AddHighlights button[type="button"]:hover p {
    color: var(--text-dark-color);
}

.AddHighlights button .Icon.down {
    width: 30px;
    min-width: 30px;
    transform: rotate(0deg);
    transition: all 150ms ease; 
}

.AddHighlights button .Icon.up {
    width: 30px;
    min-width: 30px;
    transform: rotate(180deg);
    transition: all 150ms ease; 
}

.AddHighlights .substep button p {
    line-height: 1.2;
}

.AddHighlights button:hover {
    background-color: var(--background-light-color-hover);
}

.AddHighlights button:hover {
    color: var(--text-dark-color)
}

.AddHighlights header[aria-expanded="true"] {
    background-color: var(--background-light-color-hover);
}

.AddHighlights button[aria-expanded="true"] {
    background-color: var(--background-light-color-hover);
}

.AddHighlights .ButtonWrapper.disabled button[type="button"],
.AddHighlights .ButtonWrapper.disabled button[type="button"] p, 
.AddHighlights button[type="button"]:disabled, 
.AddHighlights [type="button"]:disabled p {
    background-color: transparent;
    color: var(--text-dark-color);
}
 
.AddHighlights .accordian {
    border: 1px solid var(--border-medium-color);
    border-radius: var(--spacing-3x);
	width: 100%;
}

.AddHighlights .accordian .szh-accordion__item-panel {
    padding-bottom: var(--spacing-6x);
}

.AddHighlights .accordian .szh-accordion__item-panel:last-child img {
    padding-bottom: 0;
}

.AddHighlights .szh-accordion__item-content {
    border-top: 1px solid var(--border-medium-color);
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.AddHighlights .accordian .step {
	width: 100%;
}

.AddHighlights .accordian .step .step-title {
	font-size: var(--font-size-regular);
	font-weight: bold;
}

.AddHighlights .accordian .step .substep {
	font-size: var(--font-size-small);
    margin-top: var(--spacing-4x);
}

.AddHighlights .accordian .step .substep .szh-accordion__item-content {
	border-top: none;
    padding-top: var(--spacing-4x);
}

.AddHighlights .accordian .step .substep button[type="button"],
.AddHighlights .accordian .step .substep button[type="button"]:hover p {
    align-items: center;
	background-color: var(--background-light-color);
	border: 0px;
    border-radius: 0;
	color: var(--text-dark-color);
	width: 100%;
}

.AddHighlights .accordian .step .substep:first-child {
	margin-top: var(--spacing-4x);
}

.AddHighlights .accordian .step .step-text {
    font-weight: bold;
}

.AddHighlights .accordian .step .substep .substep-text {
    max-width: 75%;
    margin-left: var(--spacing-2x);
}

.AddHighlights .accordian .step .substep .substep-text.title {
    max-width: 30px;
}

.AddHighlights .accordian .step .substep .substep-text:first-line {
    margin-left: 0;
}
