button[type="button"].AddBookButton {
    align-items: center;
    background-color: var(--background-dark-color);
    border-radius: 50%;
    bottom: var(--spacing-10x);
    right: var(--spacing-6x);
    display: flex;
    height: 60px;
    justify-content: center;
    position: absolute;
    pointer-events: auto;
    transition: all 150ms ease;
    transition-property: background-color, color, transform;
    max-width: 60px;
}

button[type="button"].AddBookButton:hover {
    background-color: var(--green-color);
    color: var(--text-dark-color);
    transform: scale(1.15,1.15);
}

button[type="button"].AddBookButton:active {
    background-color: var(--green-color-pressed);
    color: var(--text-light-color);
    transform: scale(1,1);
}


@media only screen and (min-width: 600px) {
    button[type="button"].AddBookButton {
        right: 0;
    }
}