.SignUpHeader {
    width: 100%;
}

.SignUpHeader .title {
    font-family: var(--standard-font-family);
    font-size: var(--font-size-large);
    font-style: normal;
    font-weight: bold;
    padding: var(--spacing-6x) 0 0 0;
    text-align: left;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .SignUpHeader .title {
        font-size: var(--font-size-xlarge);
        padding: var(--spacing-6x) 0 var(--spacing-8x) 0;
        text-align: center;
    }
}