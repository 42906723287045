.ScrollSnapChild.DiscoverSection {
    background-color: #eee7d7;
    min-height: calc(1600px + 142px + 628px + 686px - var(--standard-header-height));
    max-height: calc(1600px + 142px + 628px + 686px - var(--standard-header-height));
    height: auto;
    position: relative;
}

.DiscoverSection .heading {
    position: relative;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 1.25;
    max-width: min(90%, 840px);
    margin-left: auto;
    margin-right: auto;
    padding-top: var(--spacing-24x);
    padding-left: var(--spacing-3x);
    padding-right: var(--spacing-3x);
    text-align: center;
    width: 100%;
}

.DiscoverSection .content {
    height: 100%;
    min-height: calc(1600px + 142px + 628px + 686px - var(--standard-header-height));
    max-height: calc(1600px + 142px + 628px + 686px - var(--standard-header-height));
    width: 100%;
}

.DiscoverSection .BookGrid {
    max-width: var(--max-page-width);
    width: 100%;
    margin-top: var(--spacing-16x);
    margin-bottom: 142px;
}

.DiscoverSection .AnonymousFooter {
    width: 100%;
}

.DiscoverSection .UserGrid {
    max-width: var(--max-page-width);
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--spacing-16x);
    margin-bottom: var(--spacing-4x);
    justify-content: space-between;
}

.DiscoverSection .UserGrid .UserGridItem {
    margin-bottom: var(--spacing-6x);
    min-width: 20%;
    margin-right: 0;
}

.DiscoverSection .UserGrid .AvatarBubble {
    width: 120px !important;
    height: 120px !important;
}

.DiscoverSection .view-all.experts {
    align-items: center;
    background-color: var(--background-light-color);
    color: black;
    display: flex;
    justify-content: center;
    max-width: 160px;
    margin-bottom: var(--spacing-6x);
    transition: all 150ms ease;
}

@media only screen and (max-width: 1040px) {
    .DiscoverSection .content {
        padding: 0 var(--spacing-4x);
    }
}


@media only screen and (min-width: 1000px) {
    .DiscoverSection .view-all.experts:hover {
        background-color: var(--background-dark-color);
        color: var(--white-color);
    }
}

@media only screen and (max-width: 900px) {
    .ScrollSnapChild.DiscoverSection {
        min-height: calc(2100px + 142px + 628px + 686px - var(--standard-header-height));
        max-height: calc(2100px + 142px + 628px + 686px - var(--standard-header-height));
    }
    
    .DiscoverSection .content {
        min-height: calc(2100px + 142px + 628px + 686px - var(--standard-header-height));
        max-height: calc(2100px + 142px + 628px + 686px - var(--standard-header-height));
    }
}

@media only screen and (max-width: 840px) {
    .ScrollSnapChild.DiscoverSection {
        min-height: calc(2100px + 142px + 836px + 686px - var(--standard-header-height));
        max-height: calc(2100px + 142px + 836px + 686px - var(--standard-header-height));
    }
    
    .DiscoverSection .content {
        min-height: calc(2100px + 142px + 836px + 686px - var(--standard-header-height));
        max-height: calc(2100px + 142px + 836px + 686px - var(--standard-header-height));
    }
}

@media only screen and (max-width: 720px) {
    .ScrollSnapChild.DiscoverSection {
        min-height: calc(3500px + 142px + 836px + 686px - var(--standard-header-height));
        max-height: calc(3500px + 142px + 836px + 686px - var(--standard-header-height));
    }
    
    .DiscoverSection .content {
        min-height: calc(3500px + 142px + 836px + 686px - var(--standard-header-height));
        max-height: calc(3500px + 142px + 836px + 686px - var(--standard-header-height));
    }
}

@media only screen and (max-width: 600px) {
    .ScrollSnapChild.DiscoverSection {
        min-height: calc(5500px + 142px + 1978px + 724px - var(--standard-header-height));
        max-height: calc(5500px + 142px + 1978px + 724px - var(--standard-header-height));
    }
    
    .DiscoverSection .content {
        min-height: calc(5500px + 142px + 1978px + 724px - var(--standard-header-height));
        max-height: calc(5500px + 142px + 1978px + 724px - var(--standard-header-height));
    }
}

@media only screen and (max-width: 400px) {
    .ScrollSnapChild.DiscoverSection {
        min-height: calc(4000px + 142px + 1978px + 724px - var(--standard-header-height));
        max-height: calc(4000px + 142px + 1978px + 724px - var(--standard-header-height));
    }
    
    .DiscoverSection .content {
        min-height: calc(4000px + 142px + 1978px + 724px - var(--standard-header-height));
        max-height: calc(4000px + 142px + 1978px + 724px - var(--standard-header-height));
    }
}

@media only screen and (max-width: 800px) {
    .DiscoverSection .UserGrid .UserGridItem {
        min-width: 25%;
    }
}

.DiscoverSection .UserGrid .UserGridItem p {
    font-size: var(--font-size-small);
}

@media only screen and (max-width: 700px) {
    .DiscoverSection .UserGrid .UserGridItem {
        min-width: 33%;
    }
}

@media only screen and (max-width: 400px) {

    .DiscoverSection .heading {
        font-size: 28px;
        padding-top: var(--spacing-14x);
    }

    .DiscoverSection .heading:first-child {
        padding-top: var(--spacing-16x);
    }

    .DiscoverSection .BookGrid {
        max-width: 100%;
        width: 100%;
        max-height: unset;
        overflow-y: hidden;
        margin-top: var(--spacing-12x);
    }

    .DiscoverSection .UserGrid {
        max-width: calc(100% - var(--spacing-6x));
        justify-content: space-around;
        margin-top: var(--spacing-8x);
        margin-bottom: var(--spacing-4x);
    }
    
    .DiscoverSection .UserGrid .UserGridItem {
        font-size: var(--font-size-regular);
        margin-bottom: var(--spacing-6x);
        max-width: calc(50% - var(--spacing-10x));
        min-width: calc(50% - var(--spacing-10x));
    }

    .DiscoverSection .UserGrid .AvatarBubble {
        width: 120px !important;
        height: 120px !important;
    }
}

@media only screen and (max-width: 340px) {
    .DiscoverSection .UserGrid .UserGridItem {
        min-width: 100%;
    }
}
