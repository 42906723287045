.UserGridItem {
    align-items: center;
    margin-right: var(--spacing-2x);
    margin-top: var(--spacing-2x);
    justify-content: center;
}

.UserGridItem:hover img {
    transform: scale(1,1);
    transition: transform 150ms ease;
}

.UserGridItem a {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.UserGridItem p {
    font-size: 12px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.UserGridItem:hover p {
    transition: transform 150ms ease;
}

@media only screen and (min-width: 1000px) {
    .UserGridItem:hover img {
        transform: scale(1.1,1.1);
        transition: transform 150ms ease;
    }
    
    .UserGridItem:hover p {
        transform: translateY(8px);
        transition: transform 150ms ease;
    }
}
