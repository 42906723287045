/** @format */

.AccountPage {
	align-items: flex-start;
	background-color: var(--background-dark-color);
	display: flex;
	justify-content: center;
	min-height: calc(100vh - var(--standard-header-height));
	height: 100%;
	transition: all 150ms linear;
	width: 100%;
}

.AccountPage .MaxWidthContainer {
	align-items: flex-start;
	display: flex;
	height: 100%;
	justify-content: flex-start;
}

.AccountPage .content {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	padding: 0;
	width: 100%;
}

.AccountPage .Label.page-title {
	color: var(--text-light-color);
	font-size: 32px;
	font-weight: 900;
	letter-spacing: -2px;
	line-height: var(--font-size-large);
	margin-bottom: var(--spacing-10x);
	margin-top: var(--spacing-6x);
}

.AccountPage .AccountPageSection {
	border-bottom: 1px solid var(--off-black-color);
	padding-bottom: var(--spacing-10x);
	width: 100%;
}

.AccountPage .ProfileSettingsSection {
    align-items: flex-start;
	margin-top: 0;
}

/* Forms */

.AccountPage .DEFormContainer form button[type="button"] {
    max-width: 100%;
    float: right;
}

@media only screen and (max-width: 1040px) {
    .AccountPage .content {
        padding: 0 var(--spacing-4x);
    }
}

@media only screen and (min-width: 600px) {
	.AccountPage .Label.page-title {
		font-size: var(--font-size-large);
        margin-top: var(--spacing-10x);
	}

	.AccountPage .ProfileSettingsSection {
		margin-top: var(--spacing-6x);
	}

    .AccountPage .DEFormContainer form button[type="button"] {
        max-width: 120px;
        float: right;
    }
}
