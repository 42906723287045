.DashboardPage {
    align-items: flex-start;
    background-color: var(--background-light-color);
    display: flex;
    justify-content: center;
    min-height: calc(100vh - var(--standard-header-height));
    padding-bottom: var(--spacing-40x);
    height: 100%;
    width: 100%;
}

.DashboardPage .MaxWidthContainer {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    position: relative;
}

.DashboardPage .BookGrid {
    height: 100%;
    justify-content: flex-start;
    max-width: max(400px, 100vw);
    padding: 0 var(--spacing-4x);
    margin-top: var(--spacing-6x);    
    width: 100%;
}

.DashboardPage .MaxWidthContainer.btns-footer {
    bottom: 0;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
}

.DashboardPage .no-books-yet {
    align-items: center;
    height: max(200px, calc(100vh - 422px));
    justify-content: center;
    text-align: center;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .DashboardPage .BookGrid {
        padding: 0 var(--spacing-4x);
        margin-top: var(--spacing-10x);
    }
}