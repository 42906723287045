/** @format */

.AvatarBubble {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.AvatarBubble.placeholder {
    border: 1px solid var(--border-dark-color);
    background-color: var(--off-black-color);
}

.AvatarBubble .text {
    color: var(--text-light-color);
    text-transform: uppercase;
}
