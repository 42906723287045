.FullPageLoader {
    position: relative;
    height: 100vh;
    width: 100vw;
}

.FullPageLoader .Loading {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 60%;
}

.FullPageLoader .Loading span {
    border-width: 5px !important;
}

.FullPageLoader .app-name {
    color: var(--text-dark-color);
    font-size: var(--font-size-medium);
    font-family: var(--special-font-family);
    font-style: normal;
    font-weight: 400;
    text-align: center;
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    bottom: 15%;
}