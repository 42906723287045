/** @format */

.DeleteModal {
	padding: 0;
}

.DeleteModal .spacer {
    width: 44px;
}

.DeleteModal .form-add-book {
	height: 100%;
	width: 100%;
}

.DeleteModal .content {
	padding: 0;
	position: relative;
	padding: 52px 0;
	height: 100%;
}

.DeleteModal .header {
	align-items: center;
	display: flex;
	height: 52px;
	justify-content: space-between;
	padding: var(--spacing-1x) var(--spacing-1x);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.DeleteModal .footer {
	align-items: center;
	height: 56px;
	justify-content: flex-end;
	padding: 0 var(--spacing-4x) var(--spacing-4x)
		var(--spacing-4x);
	right: 0;
	bottom: 0;
	width: 100%;
}

.DeleteModal .body {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: var(--spacing-14x) var(--spacing-4x);
	width: 100%;
}

.DeleteModal .body .book-info {
    margin-top: var(--spacing-6x);
}

.DeleteModal .body .book-cover {
	width: 130px;
    margin-right: var(--spacing-3x);
}

.DeleteModal .body .book-title {
    font-weight: bold;
	margin-bottom: var(--spacing-2x);
    line-height: 1.3;
    font-size: var(--font-size-regular);
}

.DeleteModal button.close {
	background-color: var(--white-color);
	border-radius: 50%;
	height: 40px;
	transition: all 150ms linear;
	width: 40px;
}

.DeleteModal .ButtonWrapper {
	background-color: var(--white-color);
	height: 36px;
	transition: all 150ms linear;
    min-width: 132px;
}

.DeleteModal .ButtonWrapper button {
	height: 36px;
	text-transform: none;
    min-width: 132px;
}

.DeleteModal button.close:hover {
	background-color: var(--light-grey2-color);
}

.DeleteModal .Button.nevermind {
    background-color: var(--white-color);
    border: 2px solid var(--border-light-color);
    color: var(--text-dark-color);
    justify-content: center;
    text-align: center;
}

.DeleteModal .Button.nevermind:hover {
    background-color: var(--white-color-hover);
}

.DeleteModal .Button.confirm-negative {
    background-color: var(--red-color);
    border: 2px solid var(--red-color);
    min-height: 46px;
    justify-content: center;
    text-align: center;
}

.DeleteModal .Button.confirm-negative:disabled {
    color: var(--white-color);
}

.DeleteModal .Button.confirm-negative:hover {
    background-color: var(--red-color-hover);
    border-color: var(--red-color-hover);
}

@media only screen and (max-width: 600px) {
    .DeleteModal {
        top: calc(100dvh - 280px);
        border-radius: var(--spacing-1x);
        margin-left: var(--spacing-2x);
        margin-bottom: var(--spacing-2x);
        margin-right: var(--spacing-2x);
    }
}

@media only screen and (min-width: 600px) {
	.DeleteModal {
		min-width: 300px;
	}

	.DeleteModal .footer {
		display: flex;
        position: absolute;
	}

	.DeleteModal button.close {
		display: block;
	}

    .DeleteModal .body .book-info {
        margin-top: var(--spacing-2x);
    }

    .DeleteModal .body .book-cover {
        max-width: 90px;
    }

    .DeleteModal .body .book-title {
        font-size: var(--font-size-small);
        line-height: 1.3;
        max-width: 60%;
        margin-bottom: var(--spacing-1x);
    }
    
}


.DeleteModal .Button.nevermind {

}

.DeleteModal .Button.confirm-negative {}