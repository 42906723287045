/** @format */

.ShareButton {
	align-items: center;
	border: 0;
	color: var(--white-color);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size-small);
	font-weight: bold;
	justify-content: center;
	outline: none;
    max-width: 94px;
    min-width: 94px;
    height: 44px;
}

.ShareButton .btn-title {
    color: var(--off-black-color);
    font-size: var(--font-size-small);
    font-weight: 500;
    margin-right: var(--spacing-2x);
}

button[type="button"].ShareButton {
    background-color: transparent;
    border: 2px solid #ccc8c0;
}

button[type="button"].ShareButton:hover,
button[type="button"].ShareButton:focus,
button[type="button"].ShareButton:active {
    background-color: var(--background-light-color-hover);
}


button[type="button"].ShareButton p {
	width: unset;
}

button[type="button"].ShareButton:hover p,
button[type="button"].ShareButton:focus p,
button[type="button"].ShareButton:active p {
    color: var(--text-dark-color);
}

.ShareButton:hover {
	background-color: var(--off-black-color);
}
