.CopyLocation {
    border: 2px solid #ccc8c0;
    border-radius: 30px;
    background-color: var(--white-color);
    cursor: pointer;
    height: 56px;
    flex-wrap: nowrap;
    padding: var(--spacing-4x);
    transition: all 150ms linear;
    width: 100%;
}

.CopyLocation .prompt {
    flex-grow: 1;
    flex-shrink: 0;
}

.CopyLocation .link {
    flex-grow: 0;
    flex-shrink: 1;
    width: calc(100% - 80px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 600px) {
    .CopyLocation:hover {
        background-color: var(--background-light-color-hover);
    }
}