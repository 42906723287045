/** @format */

.Highlight {
	position: relative;
}

.Highlight blockquote {
	-webkit-text-size-adjust: 100%;
	tab-size: 4;
	font-feature-settings: normal;
	font-variation-settings: normal;
	box-sizing: border-box;
	line-height: 1.625;
	padding: 0;
	cursor: pointer;
}

.Highlight mark {
	--mark-color: rgba(255, 226, 130, 0.5);
	--mark-height: 1.4em;
	--mark-skew: 0.25em;
	--mark-overlap: 0.3em;
	margin-inline: calc(var(--mark-overlap) * -1);
	padding-inline: var(--mark-overlap);
	background-color: transparent;
	background-image: linear-gradient(
			to bottom right,
			transparent 50%,
			var(--mark-color) 50%
		),
		linear-gradient(var(--mark-color), var(--mark-color)),
		linear-gradient(to top left, transparent 50%, var(--mark-color) 50%);
	background-size: var(--mark-skew) var(--mark-height),
		calc(100% - var(--mark-skew) * 2 + 1px) var(--mark-height),
		var(--mark-skew) var(--mark-height);
	background-position: 0, 50%, 100%;
	background-repeat: no-repeat;
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
	color: #000;
}

.Highlight .btn-copy-and-paste.mobile {
	display: flex;
	background-color: #f2f0f0;
	font-weight: 500;
}

.Highlight .btn-copy-and-paste.desktop {
	display: none;
}

.Highlight .location {
	color: var(--text-dark-color);
	font-weight: 500;
}

.Highlight .location.mobile {
	display: block;
}

.Highlight .location.desktop {
	display: none;
}

@media only screen and (min-width: 600px) {
	.Highlight blockquote {
		padding: var(--spacing-4x);
	}

	.Highlight .btn-copy-and-paste.mobile {
		display: none;
	}

	.Highlight .btn-copy-and-paste.desktop {
		bottom: var(--spacing-2x);
		right: var(--spacing-2x);
		display: flex;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		transition: all 150ms ease;
		width: auto;
	}

	.Highlight:hover {
		--hover-color: #f2f0f0;
		--hover-height: 100%;
		--hover-skew: 0.25em;
		--hover-overlap: 0.3em;
		background-image: linear-gradient(
				to bottom right,
				transparent 50%,
				var(--hover-color) 50%
			),
			linear-gradient(var(--hover-color), var(--hover-color)),
			linear-gradient(
				to top left,
				transparent 50%,
				var(--hover-color) 50%
			);
		background-size: var(--hover-skew) var(--hover-height),
			calc(100% - var(--hover-skew) * 2 + 1px) var(--hover-height),
			var(--hover-skew) var(--hover-height);
		background-position: 0, 50%, 100%;
		background-repeat: no-repeat;
	}

	.Highlight:hover .btn-copy-and-paste.desktop {
		display: flex;
		pointer-events: auto;
		opacity: 1;
	}

	.Highlight .location.mobile {
		display: none;
	}

	.Highlight .location.desktop {
		display: block;
	}
}
