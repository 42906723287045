/** @format */

.UpgradeModal {
    background-color: var(--off-white-color);
	padding: 0;
    bottom: 0;
    top: calc(100dvh - 440px);
    max-height: 440px;
}

.UpgradeModal .content {
	padding: 0;
	position: relative;
	padding: 0;
	height: 100%;
}

.UpgradeModal .header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: var(--spacing-6x) var(--spacing-8x);
	width: 100%;
}

.UpgradeModal .header .app-name {
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
}

.UpgradeModal .header .pro {
    font-weight: bold;
}

.UpgradeModal .header .subtitle {
    font-weight: normal;
}

.UpgradeModal .footer {
	align-items: center;
	display: none;
	height: 48px;
	justify-content: flex-end;
	padding: var(--spacing-1x) var(--spacing-3x) var(--spacing-3x)
		var(--spacing-4x);
	width: 100%;
}

.UpgradeModal .body {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
    height: 100%;
	justify-content: flex-start;
	padding: 0 var(--spacing-4x) var(--spacing-4x);
	width: 100%;
}

.UpgradeModal button.close {
	background-color: var(--off-white-color);
	border-radius: 50%;
	height: 40px;
	transition: all 150ms linear;
    position: absolute;
    right: var(--spacing-2x);
    top: var(--spacing-2x);
	width: 40px;
}

.UpgradeModal .ButtonWrapper {
	background-color: var(--white-color);
	height: 36px;
	transition: all 150ms linear;
}

.UpgradeModal .ButtonWrapper button {
	height: 36px;
	text-transform: none;
}

.UpgradeModal button.close:hover {
	background-color: var(--light-grey2-color);
}

@media only screen and (min-width: 600px) {
	.UpgradeModal {
		max-width: min(90%, 420px);
        max-height: max(500px, 50%);
        height: 100%;
        top: 10%;
	}

	.UpgradeModal .footer {
		display: flex;
	}

	.UpgradeModal button.close {
		display: block;
	}
}
