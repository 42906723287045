/** @format */

.DEFormContainer form {
	width: 100%;
}

.DEFormContainer form .rfc-form-control {
	margin-bottom: var(--spacing-4x);
}

.DEFormContainer form .rfc-input {
	border: 2px solid var(--black-color);
	background-color: var(--off-white-color);
	border-radius: 2px;
	color: var(--text-dark-color);
	font-size: var(--font-size-small);
	margin-bottom: 0;
	min-height: 48px;
	padding-top: var(--spacing-3x);
    padding-bottom: var(--spacing-3x);
}

.DEFormContainer.dark form .rfc-input {
    background-color: var(--off-black-color);
    border-color: var(--border-dark-color);
}

.DEFormContainer form .rfc-help {
	margin-top: var(--spacing-1x);
	position: relative;
}

/* Floating label */

.DEFormContainer .FloatingInput.rfc-form-control {
	position: relative;
	display: flex;
	flex-direction: column;
}

.DEFormContainer .StickyPlaceholderInput.rfc-form-control input {
	border-radius: 4px;
	color: var(--text-dark-color);
	font-size: 15px;
}

.DEFormContainer.dark .StickyPlaceholderInput.rfc-form-control input {
	color: var(--text-light-color);
}

.DEFormContainer .FloatingInput.rfc-form-control input {
	border-radius: 4px;
	color: var(--text-dark-color);
	font-size: var(--font-size-small);
}

.DEFormContainer.dark .FloatingInput.rfc-form-control input {
	color: var(--text-light-color);
}

.DEFormContainer .StickyPlaceholderInput.rfc-form-control label {
	display: none;
}

.DEFormContainer .FloatingInput.rfc-form-control label {
	position: absolute;
	pointer-events: none;
	transform: translate(0, 16px) scale(1);
	transform-origin: top left;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	color: var(--text-medium-color);
	line-height: 1;
	left: var(--spacing-3x);
}

.DEFormContainer .FloatingInput.rfc-form-control:focus-within .rfc-input,
.DEFormContainer .FloatingInput.rfc-form-control.filled .rfc-input {
	padding-bottom: 2px;
}

.DEFormContainer .FloatingInput.rfc-form-control:focus-within label,
.DEFormContainer .FloatingInput.rfc-form-control.filled label {
	transform: translate(0, 6px) scale(0.8);
	color: var(--text-medium-color);
}

.DEFormContainer form .rfc-input:focus,
.DEFormContainer form .success-0-2-3 > .rfc-input:focus {
	border-color: var(--text-dark-color);
}

.DEFormContainer.dark form .rfc-input:focus,
.DEFormContainer.dark form .success-0-2-3 > .rfc-input:focus {
	border-color: var(--text-light-color);
}
