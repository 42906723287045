.NavTextButton {
    align-items: center;
    border-radius: var(--spacing-8x);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-regular);
    justify-content: flex-start;
    padding: var(--spacing-3x) var(--spacing-6x);
    transition: all 50ms linear;
}

.NavTextButton.light:hover {
    background-color: var(--background-dark-color-hover);
}

.NavTextButton.dark:hover {
    background-color: var(--background-light-color-hover);
}

.NavTextButton.light .Label {
    color: var(--text-light-color);
}

.NavTextButton.dark .Label {
    color: var(--text-dark-color);
}

@media only screen and (max-width: 600px) {
    .NavTextButton.light:hover {
        background-color: unset;
    }
    
    .NavTextButton.dark:hover {
        background-color: unset;
    }
}