.DashboardHeader {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--spacing-4x);
    flex-direction: column;
    margin-top: var(--spacing-8x);
    position: relative;
    width: 100%;
}

.DashboardHeader .top {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.DashboardHeader .top .AvatarBubble {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
}

.DashboardHeader .full-name {
    font-size: 32px;
    text-align: center;
    line-height: 32px;
    margin-top: var(--spacing-4x);
    padding-left: var(--spacing-2x);
    padding-right: var(--spacing-2x);
    margin-left: 0;
    width: 100%;
}

.DashboardHeader .description {
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
    text-align: center;
    font-size: var(--font-size-small);
    margin-top: var(--spacing-4x);
    margin-left: 0;
}

.DashboardHeader .recs {
    font-size: 16px;
    display: inline-block;
    display: block;
}

.DashboardHeader .info {
    justify-content: center;
    width: 100%;
}

.DashboardHeader .Label.title {
    font-size: var(--font-size-large);
    font-weight: 800;
    line-height: 50px;
}

.DashboardHeader .SortMenu.mobile {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--spacing-6x);
    width: auto;
}

.DashboardHeader .SortMenu.mobile .Row {
    justify-content: space-between;
}

.DashboardHeader .socials {
    margin-top: var(--spacing-4x);
    justify-content: center;
    width: 100%;
}

.DashboardHeader .socials {
    justify-content: center;
}

.DashboardHeader .socials .Icon {
    margin-left: var(--spacing-2x);
    margin-right: var(--spacing-2x);
}

.DashboardHeader .socials .Icon:first-child {
    margin-left: 0;
}

.DashboardHeader .socials .Icon:last-child {
    margin-right: 0;
}

.DashboardHeader .wiki-link {
    cursor: pointer;
    color: var(--grey2-color);
}

.DashboardHeader .wiki-link:hover {
    text-decoration: underline;
}

@media only screen and (min-width: 600px) {
    .DashboardHeader {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        margin-top: var(--spacing-16x);
        padding: 0 var(--spacing-4x);
    }

    .DashboardHeader .Label.title {
        margin-bottom: var(--spacing-10x);
    }

    .DashboardHeader .SortMenu.desktop {
        margin-top: var(--spacing-3x);
    }

    .DashboardHeader .SortMenu.mobile {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-top: var(--spacing-6x);
        width: auto;
    }

    .DashboardHeader .top {
        flex-direction: row;
        max-width: 75%;
        align-items: flex-start;
        margin-left: 0;
        margin-right: 0;
    }

    .DashboardHeader .socials {
        justify-content: flex-start;
        margin-left: var(--spacing-6x);
        width: 100%;
    }

    .DashboardHeader .top .AvatarBubble {
        min-width: 160px;
        max-width: 160px;
        min-height: 160px;
        max-height: 160px;
    }

    .DashboardHeader .info {
        justify-content: flex-start;
    }

    .DashboardHeader .description {
        margin-left: var(--spacing-2x);
        text-align: left;
    }

    .DashboardHeader .full-name {
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        margin-left: var(--spacing-6x);
        margin-top: 0;
    }

    .DashboardHeader .SortMenu.mobile {
        margin-left: auto;
        margin-top: 0;
        margin-bottom: var(--spacing-2x);
        width: auto;
        padding-left: 0;
        padding-right: 0;
    }
}
