.HighlightsPage.WithHighlights .content.MaxWidthContainer {
    background-color: white;
    min-height: calc(100vh - 55px - 40px);
}

.HighlightsPage .highlights {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 600px) {

    .HighlightsPage.WithHighlights .content.MaxWidthContainer {
        background-color: white;
        border-left: 1px solid var(--border-light-color);
        border-right: 1px solid var(--border-light-color);
        border-top: 1px solid var(--border-light-color);
        margin-top: var(--spacing-10x);
        min-height: calc(100vh - 55px - 40px - 40px);
    }
    
}