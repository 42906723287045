/** @format */

.LandingPage {
	background-color: var(--background-light-color);
    height: calc(100vh - var(--standard-header-height));
}

.LandingPage .hero-container {
    background-color: var(--background-dark-color);
    height: 50vh;
	width: 100%;
}
