/** @format */

.IconButton {
	align-items: center;
	background-color: var(--background-light-color);
	border: 0px;
	border-radius: 4px;
	cursor: pointer;
	box-shadow: none;
	display: flex;
	font-size: var(--font-size-body);
	font-weight: bold;
	justify-content: center;
	outline: none;
	vertical-align: middle;
	min-height: 30px;
	transition: all 150ms linear;
}