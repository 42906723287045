/* Desktop */
.OptionsMenu.desktop {
    display: none;
}

/* Mobile */

.OptionsMenu.mobile,
.OptionsMenu.mobile .Button {
    align-items: center;
    display: inline-flex;
    max-height: 40px;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.OptionsMenu.mobile .Button {
    background-color: var(--background-light-color);
    border: none;
    color: var(--text-dark-color);
    font-weight: 400;
}

.OptionsMenu .option-text.active {
    font-weight: bold;
}

.OptionsMenu .option-icon-container {
    display: block;
}

.OptionsMenu.mobile .drawer {
    border-radius: var(--spacing-4x);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: var(--spacing-2x);
    padding-top: var(--spacing-2x);
    width: 100%;
}

.OptionsMenu.mobile .drawer .option {
    text-align: left;
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
    width: 100%;
}

.OptionsMenu.mobile .drawer .option .Row {
    align-items: center;
    border-bottom: 1px solid var(--border-light-color);
    justify-content: flex-start;
    height: 50px;
    text-align: left;
    width: 100%;
}

.OptionsMenu.desktop .szh-menu {
    background-color: var(--white-color);
}

.OptionsMenu.mobile .drawer .option:last-child .Row {
    border-bottom: 0px;
}

.OptionsMenu.mobile {
    border: 2px solid #ccc8c0;
    border-radius: var(--spacing-1x);
}

@media only screen and (min-width: 600px) {

    /* Mobile */
    .OptionsMenu.mobile {
        display: none;
    }

    /* Desktop */
    .OptionsMenu.desktop {
        display: flex;
    }

    .OptionsMenu.desktop,
    .OptionsMenu.desktop .szh-menu-button {
        align-items: center;
        display: flex;
        max-height: 44px;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .OptionsMenu.desktop .szh-menu-button {
        background-color: var(--background-light-color);
        border: 2px solid #ccc8c0;
        color: var(--text-dark-color);
        padding: var(--spacing-2x);
    }

    .OptionsMenu.desktop .szh-menu-button:hover,
    .OptionsMenu.desktop .szh-menu-button.szh-menu-button--open {
        background-color: var(--background-light-color-hover);
    }

    .OptionsMenu.desktop .szh-menu {
        background-color: var(--white-color);
        padding: var(--spacing-2x);
        min-width: 210px;
    }

    .OptionsMenu.desktop .szh-menu__item {
        border-radius: var(--spacing-1x);
        padding-left: var(--spacing-2x);
        padding-right: var(--spacing-2x);
    }

    .OptionsMenu.desktop .szh-menu__item.szh-menu__item--hover {
        background-color: var(--background-light-color-hover);
    }

    .OptionsMenu.desktop button[type="button"] {
        border: 2px solid #ccc8c0;
        line-height: 40px;
        height: 40px;
        width: 100%;
    }
    
    .OptionsMenu.desktop > button > p {
        color: var(--text-dark-color);
    }
    
    .OptionsMenu.desktop > button:hover > p {
        color: var(--text-dark-color);
    }
    
}