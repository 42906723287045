/** @format */

.LogInForm {
	background-color: var(--background-light-color);
	justify-content: space-between;
	height: 100%;
	width: 100%;
}

@media only screen and (min-width: 600px) {
	.LogInForm {
		justify-content: center;
	}
}
