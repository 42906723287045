.SignUpPage {
    align-items: flex-start;
    background-color: var(--background-light-color);
    display: flex;
    justify-content: center;
    min-height: calc(100vh - var(--standard-header-height));
    height: 100%;
    width: 100%;
}

.SignUpPage .MaxWidthContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.SignUpPage .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: min(400px, 100vw);
    padding: 0 var(--spacing-4x);
    width: 100%;
}

.SignUpPage .SignUpForm {
    min-height: 75vh;
}

@media only screen and (min-width: 600px) {
    .SignUpPage {
        align-items: center;
    }

    .SignUpPage .content {
        max-width: 400px;
    }
}