/** @format */

.EmptyBook {
	cursor: pointer;
}

.EmptyBook .cover {
	align-items: center;
	background-color: white;
	box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 2px -1px,
		rgba(0, 0, 0, 0.01) 0px 1px 2px -1px;
	border: 1px solid var(--light-grey2-color);
	display: flex;
	height: 242px;
	justify-content: center;
	padding: var(--spacing-6x);
	text-align: center;
	transition: all 100ms linear;
	width: 164px;
}

.EmptyBook .cover:hover {
	background-color: var(--off-white-color);
	transform: translate(0, -12px) rotate(1deg) scaleX(1.02) scaleY(1.02);
}
