/**
 * Imports
 *
 * @format
 */

/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

/* Vars */
:root {
    --background-light-color: #fffaf0;
    --background-light-color-hover: rgb(238, 237, 232);
    --background-dark-color: #000000;
    --background-dark-color-hover: #181818;
    --text-dark-color: #181617;
    --text-medium-color: #737373;
    --text-light-color: #fff;
    --border-dark-color: rgb(70, 70, 70);
    --border-medium-color: rgb(133, 133, 133);
    --border-light-color: rgb(233, 233, 233);
    --border-color: #d4d4d4;
    /* White and Black */
    --white-color: #ffffff;
    --white-color-hover: #f2f2f2;
    --off-white-color: #fafafa;
    --black-color: #000000;
    --off-black-color: #262626;
    /* Greys */
    --grey1-color: #5f6b7c;
    --grey2-color: #738091;
    /* Light Greys */
    --light-grey1-color: #d3d8de;
    --light-grey2-color: #dce0e5;
    /* Others */
    --light-red-color: #fff7f9;
    --red-color: #e50038;
    --red-color-hover: #92222a;
    --green-color: #23d5ab;
    --green-color-pressed: #1eb793;
    --yellow-color: rgb(255, 253, 146);
    --amazon-color: #ff9900;
    /* Font Sizes */
    --font-size-hero: 60px;
    --font-size-xxlarge: 70px;
    --font-size-xlarge: 50px;
    --font-size-large: 36px;
    --font-size-medium: 28px;
    --font-size-regular: 20px;
    --font-size-small: 14px;
    --font-size-button: 12px;
    --font-size-body: 16px;
    --font-size-xsmall: 12px;
    --font-size-tag: 11px;
    --font-size-button: 16px;
    /* Spacing */
    --spacing-1x: 4px;
    --spacing-2x: 8px;
    --spacing-3x: 12px;
    --spacing-4x: 16px;
    --spacing-5x: 20px;
    --spacing-6x: 24px;
    --spacing-7x: 28px;
    --spacing-8x: 32px;
    --spacing-9x: 36px;
    --spacing-10x: 40px;
    --spacing-12x: 48px;
    --spacing-13x: 52px;
    --spacing-14x: 56px;
    --spacing-15x: 60px;
    --spacing-16x: 64px;
    --spacing-18x: 72px;
    --spacing-20x: 80px;
    --spacing-22x: 88px;
    --spacing-24x: 96px;
    --spacing-26x: 104px;
    --spacing-28x: 112px;
    --spacing-30x: 120px;
    --spacing-32x: 128px;
    --spacing-40x: 160px;
    /* Sizing */
    --max-page-width: 1000px;
    --mobile-max-page-width: 100%;
    --standard-container-padding: var(--spacing-6x);
    --large-banner-height: var(--spacing-22x);
    --extended-header-height: 64px;
    --standard-header-height: 55px;
    --standard-footer-height: 92px;
    --small-banner-height: var(--spacing-10x);
    --standard-border-radius: var(--spacing-3x);
    --min-button-height: 34px;
    --standard-font-family: "Noto Sans", sans-serif;
    --special-font-family: "DM Serif Display", serif;
}

/* Clear styles */

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}

/*
  2. Remove default margin
*/
* {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
    background-color: var(--background-dark-color);
    height: 100vh;
    min-height: 100vh !important;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: var(--standard-font-family);
}

button {
    color: #000;
}
select {
    color: #000;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
    font: inherit;
    border: 1px solid var(--border-medium-color);
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-dark-color);
    overflow-wrap: break-word;
    margin: 0;
}

h1 {
    font-size: var(--font-size-xlarge);
    font-weight: bold;
}

h2 {
    font-size: var(--font-size-large);
    font-weight: 400;
}

h3 {
    font-size: var(--font-size-medium);
    font-weight: 400;
}

h4 {
    font-size: var(--font-size-regular);
}

h5 {
    font-size: var(--font-size-regular);
}

p,
h6 {
    font-size: var(--font-size-small);
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
    isolation: isolate;
}

#root {
    height: 100%;
}

/* Etc */

.App {
    height: 100%;
}

body {
    background-color: var(--background-dark-color);
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
    width: 0px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* General */

a,
a:focus,
a:visited {
    color: inherit;
    transition: color 100ms ease-in;
    outline: none;
    text-decoration: none;
}

a:hover {
    color: var(--grey3-color);
    outline: none;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.relative {
    position: relative;
}

.aspect-cover {
    aspect-ratio: 6/9;
    object-fit: cover;
}

.bold {
    font-weight: bold;
}

.semibold {
    font-weight: 500;
}

.non-bold {
    font-weight: normal;
}

.unrounded {
    border-radius: 0px;
}

.touchable {
    cursor: pointer;
}

.font-size-button {
    font-size: var(--font-size-button);
}

.font-size-tag {
    font-size: var(--font-size-tag);
}

.font-size-hero {
    font-size: var(--font-size-hero);
}

.font-size-body {
    font-size: var(--font-size-body);
}

.font-size-small {
    font-size: var(--font-size-small);
}

.font-size-xsmall {
    font-size: var(--font-size-xsmall);
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

/* Colors */

.black-color {
    color: var(--black-color);
}

.grey1-color {
    color: var(--grey1-color);
}

.grey2-color {
    color: var(--grey2-color);
}

.light-grey1-color {
    color: var(--light-grey1-color);
}

.light-grey2-color {
    color: var(--light-grey2-color);
}

.off-black-color {
    color: var(--off-black-color);
}

.white-color {
    color: var(--white-color);
}

.off-white-color {
    color: var(--off-white-color);
}

.green-color {
    color: var(--green-color);
}

.red-color {
    color: var(--red-color);
}

/* Backgrounds */

.bg-red-color {
    background-color: var(--red-color);
}

*.bg-red-color:hover {
    background-color: var(--red-color-hover);
}

/* Border */

.no-bottom-border {
    border-bottom: 0px !important;
}

/* Float */

.float-right {
    float: right;
}

.float-left {
    float: left;
}

/* Inline flex */

.inline-flex {
    display: inline-flex;
}

/* Full height */

.full-height {
    height: 100%;
}

/* Full width */

.full-width {
    width: 100%;
}

.text-link {
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
}

/* Positioning */

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

/* Buttons */

button[type="button"] {
    align-items: center;
    background-color: var(--black-color);
    border: 0px;
    border-radius: var(--spacing-1x);
    color: var(--text-light-color);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-small);
    line-height: 40px;
    font-weight: bold;
    transition: all 100ms ease;
    width: 100%;
}

button[type="button"] p {
    color: var(--text-light-color);
    font-size: var(--font-size-button);
    line-height: 40px;
    width: 100%;
}

button[type="button"]:hover {
    background-color: var(--off-black-color);
    border: 0px;
    color: var(--white-color);
}

button[type="button"]:hover p {
    color: var(--white-color);
}

.ButtonWrapper.disabled button[type="button"],
.ButtonWrapper.disabled button[type="button"] p,
button[type="button"]:disabled,
button[type="button"]:disabled p {
    background-color: var(--light-grey2-color);
    cursor: not-allowed;
    color: var(--grey2-color);
    pointer-events: none;
}

/* Button dark */

button[type="button"].light {
    background-color: var(--white-color);
    color: var(--text-dark-color);
}

button[type="button"].light p {
    color: var(--text-dark-color);
}

button[type="button"].light:hover {
    background-color: var(--light-grey1-color);
    color: var(--text-dark-color);
}

button[type="button"].light:hover p {
    color: var(--text-dark-color);
}

/* React Menu */

/* Animation */

.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

/* Toastify */

.Toastify__toast-container {
    z-index: 200000;
}

/* Overlay */

.ModalOverlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.35);
}

.Modal {
    position: absolute;
    inset: 0px;
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
}

@media only screen and (min-width: 600px) {
    .Modal {
        position: absolute;
        inset: 0px;
        border: 0;
        background: rgb(255, 255, 255);
        box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
            rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
        border-radius: var(--spacing-3x);
        overflow: auto;
        outline: none;
        padding: 20px;
        max-width: 500px;
        max-height: 360px;
        left: 50%;
        top: 10%;
        transform: translateX(-50%);
    }
}
