/** @format */

.ProfileSettingsSection {
	color: var(--light-text-color);
	flex-direction: column;
	flex-grow: 1;
}

.ProfileSettingsSection .headers,
.ProfileSettingsSection .form-container {
	margin-top: var(--spacing-4x);
	width: 100%;
}

.ProfileSettingsSection .form-container {
	margin-top: var(--spacing-4x);
	max-width: 100%;
}

.ProfileSettingsSection .section-title {
	color: var(--light-text-color);
	font-size: var(--font-size-body);
}

.ProfileSettingsSection .section-subtitle {
	color: var(--grey2-color);
	font-size: var(--font-size-body);
}

@media only screen and (min-width: 600px) {
	.ProfileSettingsSection {
		flex-direction: row;
	}

	.ProfileSettingsSection .form-container {
		margin-top: 0;
		max-width: min(340px, 100%);
	}

	.ProfileSettingsSection .headers,
	.ProfileSettingsSection .form-container {
		margin-top: 0;
	}
}
