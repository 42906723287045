.BuiltBy {
    background-color: white;
    border: 1px solid rgba(50,50,50);
    border-top-left-radius: 8px;
    border-bottom: 0;
    border-right: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px -2px 0px, rgba(0, 0, 0, 0.1) 0px 0px -1px 0px;
    cursor: pointer;
    font-size: var(--font-size-xsmall);
    font-weight: bold;
    position: fixed;
    bottom: 0;
    padding: 6px 8px;
    right: 0;
    z-index: 1000000;
}