.BookGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-inline-start: 0;
    gap: min(3vh, 1.5rem);
    column-gap: 1.8rem;
    row-gap: min(3vh, 1.5rem);
}

@media screen and (max-width: 600px) {
    .BookGrid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: min(8vh, 3rem); /* reduce gap on mobile */
    }

    .BookGrid .BookGridItem {
        max-width: calc(50% - var(--spacing-4x));
        min-width: calc(50% - var(--spacing-4x));
    }
}
