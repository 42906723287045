/** @format */

.AddBookModal {
	padding: 0;
}

.AddBookModal .form-add-book {
	height: 100%;
	width: 100%;
}

.AddBookModal .content {
	padding: 0;
	position: relative;
	padding: 52px 0;
	height: 100%;
}

.AddBookModal .header {
	align-items: center;
	display: flex;
	height: 52px;
	justify-content: space-between;
	padding: var(--spacing-1x) var(--spacing-1x);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.AddBookModal .footer {
	align-items: center;
	display: none;
	height: 48px;
	justify-content: flex-end;
	padding: var(--spacing-1x) var(--spacing-3x) var(--spacing-3x)
		var(--spacing-4x);
	position: absolute;
	right: 0;
	bottom: 0;
	width: 200px;
}

.AddBookModal .body {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0 var(--spacing-4x);
	width: 100%;
}

.AddBookModal .url,
.AddBookModal .url:active,
.AddBookModal .url:focus {
	border-bottom: 1px solid var(--border-color);
	height: 150px;
	overflow: auto;
    opacity: 1;
    transition: all 150ms ease;
}

.AddBookModal .url.hide {
    height: 0px;
    opacity: 0;
}

.AddBookModal .body .book-info {
    margin-top: var(--spacing-6x);
}

.AddBookModal .body .book-cover {
	width: 130px;
    margin-right: var(--spacing-3x);
}

.AddBookModal .body .book-title {
    font-weight: bold;
	margin-bottom: var(--spacing-2x);
    line-height: 1.3;
    font-size: var(--font-size-regular);
}

.AddBookModal button.close {
	background-color: var(--white-color);
	border-radius: 50%;
	display: none;
	height: 40px;
	transition: all 150ms linear;
	width: 40px;
}

.AddBookModal button.back {
	display: block;
	background-color: var(--white-color);
	border-radius: 50%;
	height: 40px;
	transition: all 150ms linear;
	width: 40px;
}

.AddBookModal .ButtonWrapper {
	background-color: var(--white-color);
	height: 36px;
	transition: all 150ms linear;
    min-width: 132px;
}

.AddBookModal .ButtonWrapper button {
	height: 36px;
	text-transform: none;
    min-width: 132px;
}

.AddBookModal textarea,
.AddBookModal textarea:focus,
.AddBookModal textarea:active,
.AddBookModal textarea:hover {
	border: 0px;
	outline: none;
	width: 100%;
	resize: none;
}

.AddBookModal textarea.rfc-input,
.AddBookModal textarea.rfc-input:focus,
.AddBookModal textarea.rfc-input:active,
.AddBookModal textarea.rfc-input:hover,
.AddBookModal input.rfc-input,
.AddBookModal input.rfc-input:focus,
.AddBookModal input.rfc-input:active,
.AddBookModal input.rfc-input:hover {
	border: 0px;
	outline: none;
}

.AddBookModal button.close:hover {
	background-color: var(--light-grey2-color);
}

.AddBookModal .body .mobile-continue {
    border-radius: var(--spacing-5x);
	margin-left: auto;
	margin-top: var(--spacing-3x);
	height: 36px;
    line-height: 34px;
	padding-left: var(--spacing-5x);
	padding-right: var(--spacing-5x);
	text-transform: none;
	width: unset;
}

.AddBookModal .body .mobile-continue p,
.AddBookModal .body .desktop-continue p {
    line-height: 34px;
}

.AddBookModal .body .mobile-continue {
    min-width: 132px;
	display: block;
    transition: all 150ms ease;
}

.AddBookModal .body .desktop-continue {
    border-radius: var(--spacing-5x);
    min-width: 132px;
	display: none;
    transition: all 150ms ease;
}

.AddBookModal .body .mobile-continue.hide {
    opacity: 0;
}

.AddBookModal .body .desktop-continue.hide {
    opacity: 0;
}

.AddBookModal .header .post {
    border-radius: var(--spacing-5x);
    height: 36px;
    margin-right: var(--spacing-2x);
    padding: 0 var(--spacing-4x);
    text-transform: none;
    min-width: 102px;
    width: auto;
}

.AddBookModal .header .post:disabled {
    background-color: var(--background-dark-color);
}

.AddBookModal .reset {
    background-color: var(--light-grey2-color);
    cursor: pointer;
    padding-right: var(--spacing-3x);
}

.AddBookModal .reset:hover {
    background-color: var(--light-grey1-color);
}


@media only screen and (min-width: 600px) {
	.AddBookModal {
		min-width: min(90%, 600px);
	}

	.AddBookModal .footer {
		display: flex;
	}

	.AddBookModal button.close {
		display: block;
	}

	.AddBookModal button.back {
		display: none;
	}

	.AddBookModal .body .mobile-continue {
		display: none;
	}

	.AddBookModal .body .desktop-continue {
		display: flex;
	}

	.AddBookModal .url,
	.AddBookModal .url:active,
	.AddBookModal .url:focus {
		border-bottom: unset;
		height: 86px;
		overflow: auto;
	}

    .AddBookModal .body .book-info {
        margin-top: var(--spacing-2x);
    }

    .AddBookModal .body .book-cover {
        max-width: 90px;
    }

    .AddBookModal .body .book-title {
        font-size: var(--font-size-small);
        line-height: 1.3;
        max-width: 60%;
        margin-bottom: var(--spacing-1x);
    }
    
}
