.PeoplePage .users-container {
    max-width: 1030px;
}

.PeoplePage .UserGrid {
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--spacing-8x);
    margin-bottom: var(--spacing-30x);
    justify-content: space-between;
}

.PeoplePage .UserGrid::after {
    content: "";
    flex: auto;
}

.PeoplePage .top {
    align-items: center;
    justify-content: space-between;
    padding: 0 0;
    padding-top: var(--spacing-10x);
    max-width: min(calc(100vw - var(--spacing-8x)), 1040px);
    margin-left: auto;
    margin-right: auto;
}

.PeoplePage .UserGrid .UserGridItem {
    margin-bottom: var(--spacing-6x);
    min-width: 15%;
    margin-right: 0;
}

.PeoplePage .UserGrid .UserGridItem p {
    font-size: var(--font-size-small);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 140%;
}

.PeoplePage .UserGrid .AvatarBubble {
    width: 120px !important;
    height: 120px !important;
}

.PeoplePage .ais-SearchBox-form {
    background-color: transparent;
}

.PeoplePage .ais-SearchBox {
    margin-top: var(--spacing-2x);
    max-width: 310px;
    width: 100%;
}

.PeoplePage .ais-SearchBox-input {
    border-radius: var(--spacing-8x);
    border: 2px solid black;
    box-shadow: none;
}

.PeoplePage .ais-SearchBox-input::placeholder {
    color: var(--grey2-color);
}

@media only screen and (max-width: 800px) {

    .PeoplePage .top {
        padding-top: var(--spacing-6x);
    }

    .PeoplePage .UserGrid .UserGridItem {
        min-width: 25%;
    }
}

@media only screen and (max-width: 700px) {
    .PeoplePage .UserGrid .UserGridItem {
        min-width: 33%;
    }

    .PeoplePage .top {
        flex-direction: column;
    }

    .PeoplePage .ais-SearchBox {
        margin-top: var(--spacing-6x);
        max-width: 300px;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .PeoplePage .UserGrid {
        max-width: calc(100% - var(--spacing-6x));
        justify-content: space-around;
        margin-top: var(--spacing-8x);
        margin-bottom: var(--spacing-20x);
    }

    .PeoplePage .UserGrid .UserGridItem {
        font-size: var(--font-size-regular);
        margin-bottom: var(--spacing-6x);
        min-width: 50%;
    }

    .PeoplePage .UserGrid .AvatarBubble {
        width: 120px !important;
        height: 120px !important;
    }
}

@media only screen and (max-width: 340px) {
    .PeoplePage .UserGrid .UserGridItem {
        min-width: 100%;
    }
}
