/** @format */

.Action {
    margin-right: var(--spacing-2x);
	max-width: 100%;
}

.Action:last-child {
    margin-right: 0;
}

.Action .link {
	color: var(--text-dark-color);
	font-size: var(--font-size-small);
	text-decoration: underline;
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    display: block;
}

.Action .link-button {
    background-color: var(--white-color);
	color: var(--text-dark-color);
	font-size: var(--font-size-small);
    font-weight: bold;
    padding: var(--spacing-1x) var(--spacing-2x);
    margin-right: -8px;
	text-decoration: none;
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    display: block;
    --mark-color: rgba(255,226,130,.5);
    --mark-height: 1.8em;
    --mark-skew: 0.25em;
    --mark-overlap: 0.3em;
    margin-inline: calc(var(--mark-overlap)* -1);
    padding-inline: var(--mark-overlap);
    background-color: transparent;
    background-image: linear-gradient(to bottom right, transparent 50%, var(--mark-color) 50%), linear-gradient(var(--mark-color), var(--mark-color)), linear-gradient(to top left, transparent 50%, var(--mark-color) 50%);
    background-size: var(--mark-skew) var(--mark-height), calc(100% - var(--mark-skew)* 2 + 1px) var(--mark-height), var(--mark-skew) var(--mark-height);
    background-position: 0, 50%, 100%;
    background-repeat: no-repeat;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    color: #000;
}

.Action .Icon {
    min-width: 20px;
}

.Action .divider {
    margin-left: var(--spacing-1x);
    margin-right: var(--spacing-2x);
    flex-shrink: 0;
}


@media only screen and (min-width: 600px) {
	.Action {
		max-width: 100%;
	}

    .Action .link-button {
        margin-right: 0px;
    }
}
