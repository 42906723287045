.Book .BookCover {
    min-width: 154px;
	max-width: 154px;
	margin-left: auto;
	margin-right: auto;
	margin-top: var(--spacing-3x);
	transition: none;
}

.Book .BookCover .cover,
.Book .BookCover .cover img {
    min-height: 240px;
    min-width: 154px;
}

.Book .title {
	font-size: var(--font-size-medium);
	max-width: 500px;
	padding-left: var(--spacing-6x);
	padding-right: var(--spacing-6x);
}

.Book .subtitle {
	font-size: var(--font-size-regular);
	max-width: 80%;
	font-weight: 200;
	max-width: 500px;
	padding-left: var(--spacing-6x);
	padding-right: var(--spacing-6x);
}

.Book .authors-names {
	font-size: var(--font-size-small);
}

.BookPage .buttons-container {
    top: 0;
    position: absolute;
    right: var(--spacing-3x);
}

.BookPage .description {
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    line-clamp: 7;
    line-height: 1.4;
    -webkit-box-orient: vertical;
    font-size: 14px;
    margin-top: var(--spacing-8x);
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
    max-width: 500px;
}

.BookPage .show-more {
	background: rgb(238, 237, 232);
	background: linear-gradient(
		0deg,
		#fffaf0 0%,
		#fffaf0 68%,
		transparent 100%
	);
    cursor: pointer;
	font-weight: bold;
    font-size: 12px;
	margin-top: 0;
    height: 55px;
    line-height: 55px;
    margin-top: -20px;
    padding-top: 5px;
	width: 100%;
	text-align: center;
    vertical-align: middle;
}

.BookPage .show-more:hover {
    color: var(--text-medium-color);
    text-decoration: none;
}

.BookPage .readers {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    margin-top: var(--spacing-2x);
    text-align: left;
    padding-left: var(--spacing-4x);
	padding-right: var(--spacing-4x);
    width: 100%;
}

.BookPage .UserGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
}

.BookPage .UserGrid .UserGridItem {
    flex-basis: 20%;
    max-width: 20%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: var(--spacing-3x);
}

.BookPage .UserGrid .UserGridItem p {
    max-width: 60px;
}

.BookPage .options-menu-container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
    max-width: min(500px, 100%);
    width: 100%;
}

@media only screen and (min-width: 600px) {
	.BookPage .BookCover {
		max-width: 200px;
		margin-top: var(--spacing-4x);
	}

    .BookPage .description {
        margin-top: var(--spacing-10x);
    }

    .BookPage .readers {
        margin-top: var(--spacing-8x);
    }
}