.ShareContent {
    align-items: center;
}

.ShareContent .btn-social-share {
    align-items: center;
    border-radius: 50%;
    border: 2px solid #ccc8c0 !important;
    background-color: white !important;
    display: flex;
    height: 56px;
    justify-content: center;
    margin-left: 6px;
    margin-right: 6px;
    width: 56px;
}

.ShareContent .socials-container {
    flex-wrap: wrap;
    row-gap: 12px; 
    max-width: 100%;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .ShareContent .btn-social-share:hover {
        background-color: var(--background-light-color-hover) !important;
    }
}
